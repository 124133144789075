import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HotelDescriptionService } from '@shared/services/hotel-description.service';
import { CancellationPolicyUtil } from '@shared/utils/cancellation-policy.util';

@Injectable({ providedIn: 'root' })
export class BookingResolver implements Resolve<any> {
  constructor(private hotelDescriptionService: HotelDescriptionService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    return this.hotelDescriptionService
      .getHotelDetails(route.queryParams.id)
      .pipe(
        map(data => data.data),
        map((data: any) => {
          const cancellationPolicy = CancellationPolicyUtil.getPolicy(
            data.provider_name.split('/')[0],
            data,
            route.queryParams.checkin
          );
          return {
            data,
            cancellationPolicy,
          };
        })
      );
  }
}
