import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { ForgotPasswordModule } from '../forgot-password/forgot-password.module';
import { RegisterModule } from '../register/register.module';
import { LoginSharedModule } from '../login-shared/login-shared.module';
import { ResetPasswordModule } from '../reset-password/reset-password.module';
import { NewPasswordModule } from '../new-password/new-password.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoginSharedModule,
    ForgotPasswordModule,
    RegisterModule,
    ResetPasswordModule,
    NewPasswordModule,
  ],
})
export class LoginModule {}
