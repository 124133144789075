import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HeaderComponent } from './header.component';
import { HeaderService } from './header.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { RouterModule } from '@angular/router';
import { SearchBarModule } from '@v2/common/components/search-bar/search-bar.module';
import { LoginModule } from 'src/app/auth/components/login/login.module';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    SearchBarModule,
    RouterModule,
    LoginModule,
    MatMenuModule,
  ],
  exports: [HeaderComponent, FormsModule, ReactiveFormsModule],
  declarations: [HeaderComponent],
  providers: [HeaderService],
})
export class HeaderModule {}
