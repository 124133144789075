<div
  id="loadingIndicator"
  class="position-fixed w-100 h-100 text-center justify-items-center"
  *ngIf="isLoading">
  <div class="lodericon">
    <img
      loading="lazy"
      class="loderimage"
      src="/assets/Icons/Icons/General icons and logos/Loading animation.svg" />
    <div class="loderbt-spinner"></div>
  </div>
</div>
<main id="main" class="main" [ngClass]="{ menuFixed: isMobileMenuOpened }">
  <router-outlet></router-outlet>
</main>
