<div>
  <div class="row" (click)="onCancel()">
    <h1 style="padding: 10px 10px 0 0; margin: 0">
      <i
        class="fa fa-times"
        aria-hidden="true"
        style="float: right; cursor: pointer"></i>
    </h1>
  </div>
  <div style="width: 100%; padding-right: 20px; padding-left: 20px">
    <p
      style="
        font: normal normal 600 30px Soleil;
        color: #4a4a4a;
        margin: 0 0 20px 0;
      ">
      Reset your password
    </p>
    <form [formGroup]="newPassForm">
      <input
        type="password"
        placeholder="New Password"
        formControlName="password" />
      <input
        type="password"
        placeholder="Confirm Password"
        formControlName="confirmPassword" />
    </form>
  </div>

  <!-- Alerts -->
  <div class="text-center">
    <p
      style="font: normal normal 15px Soleil; color: #d35551"
      *ngIf="confPasswordNotMatchErr">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      Confirm Password doesn't match!
    </p>
    <p
      style="font: normal normal 15px Soleil; color: #d35551"
      *ngIf="passwordLengthError">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      Password length should be greater than 5!
    </p>
    <p
      style="font: normal normal 15px Soleil; color: #4a4a4a"
      *ngIf="passwordResetSuccessfulAlert">
      <i class="fa fa-check" aria-hidden="true"></i> Password reset successful!
    </p>
  </div>

  <div
    class="row"
    style="margin: 5px 0 10px 0; padding-right: 20px; padding-left: 20px"
    *ngIf="isLoading === false">
    <button
      class="btn btn-danger login-btn"
      [disabled]="!newPassForm.valid"
      (click)="onResetPassword()">
      <span>Reset password</span>
      <i class="fa fa-arrow-right"></i>
    </button>
  </div>
  <div
    class="row"
    style="margin: 8px 0 8px 0; padding-right: 20px; padding-left: 20px"
    *ngIf="isLoading === true">
    <div
      class="text-center"
      style="
        background-color: #d35551;
        color: white;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
      ">
      <div class="loader"></div>
    </div>
  </div>

  <div
    class="row"
    style="margin: 30px 0 30px 0; padding-right: 20px; padding-left: 20px"
    (click)="onBackToLogin()">
    <p
      style="
        font: normal normal 600 12px Soleil;
        color: gray;
        margin: 0;
        cursor: pointer;
      ">
      Back to login
    </p>
  </div>
</div>
