<ng-container *ngIf="isForgotPasswordActive">
  <div class="">
    <div style="width: 100%; padding-right: 20px; padding-left: 20px">
      <p
        style="
          font: normal normal 600 30px Soleil;
          color: #4a4a4a;
          margin: 0 0 20px 0;
        ">
        Reset your password
      </p>

      <p style="padding: 0 2px">
        Enter the email you used to book with SkiYodl to create your account
      </p>

      <input type="email" placeholder="Email" [formControl]="emailCtrl" />
    </div>

    <!-- Alerts -->
    <div class="text-center">
      <p
        style="font: normal normal 15px Soleil; color: #d35551"
        *ngIf="errorMsg">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{ errorMsg }}!
      </p>
      <p
        style="font: normal normal 15px Soleil; color: #4a4a4a"
        *ngIf="OTPSentAlert">
        <i class="fa fa-check" aria-hidden="true"></i> OTP sent to your email!
      </p>
    </div>

    <div
      class="row"
      style="margin: 5px 0 10px 0; padding-right: 20px; padding-left: 20px"
      *ngIf="isLoading === false">
      <button
        class="btn btn-danger login-btn"
        [disabled]="!emailCtrl.valid"
        (click)="onReset()">
        <span>Reset password</span>
        <i class="fa fa-arrow-right"></i>
      </button>
    </div>
    <div
      class="row"
      style="margin: 8px 0 8px 0; padding-right: 20px; padding-left: 20px"
      *ngIf="isLoading === true">
      <div
        class="text-center"
        style="
          background-color: #d35551;
          color: white;
          padding: 10px;
          border-radius: 5px;
          cursor: pointer;
        ">
        <div class="loader"></div>
      </div>
    </div>

    <div
      class="row"
      style="margin: 30px 0 30px 0; padding-right: 20px; padding-left: 20px"
      (click)="onBackToLogin()">
      <p
        style="
          font: normal normal 600 12px Soleil;
          color: gray;
          margin: 0;
          cursor: pointer;
        ">
        Back to login
      </p>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isOtpScreenActive">
  <app-reset-password
    (backToLoginClicked)="onBackToLogin()"></app-reset-password>
</ng-container>
