import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginSharedModule } from '../login-shared/login-shared.module';
import { ResetPasswordModule } from '../reset-password/reset-password.module';

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoginSharedModule,
    ResetPasswordModule,
  ],
  exports: [ForgotPasswordComponent],
})
export class ForgotPasswordModule {}
