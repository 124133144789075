import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseHttpService } from './base-http-service';
import { LegacyResponse } from '../models/legacy-response.model';
import { ActiveLocations } from '../models/active-locations.model';
import { SkiArea } from '../models/ski-area.model';
import { ResortInformation } from '../models/resort-details.model';
import { Country } from '../models/country.model';
import { HttpParams } from '@angular/common/http';
import { AvailableDatesResponse } from '../models/available-dates-response.model';
import { slugify } from '../pipes/slugify.pipe';
import { DatesResponse } from '@shared/models/calendar-response.model';

export interface LocationConfiguration {
  activeLocations: ActiveLocations;
  activeLocationsList: LocationItem[];
  availableDates: DatesResponse;
  propertyDates: DatesResponse;
}

export interface LocationDetailInfo {
  id: string;
  name: string;
}

export interface LocationItem extends LocationDetailInfo {
  type: LocationItemType;
  country: LocationDetailInfo;
  resort: LocationDetailInfo;
  heroImage?: {
    url: string;
  };
  skiArea?: LocationDetailInfo;
  subResort?: LocationDetailInfo;
  ref: string;
}

export interface PropertyLocation {
  id: string;
  type: string;
  name: string;
}

enum LocationItemType {
  Country = 'country',
  Resort = 'resort',
  SubResort = 'subResort',
  SkiArea = 'skiArea',
}

enum LocationType {
  Country = 'country',
  SkiArea = 'ski_areas',
  Resort = 'resort',
}

@Injectable({ providedIn: 'root' })
export class LocationService extends BaseHttpService {
  propertyLocation: PropertyLocation;

  private configuration: LocationConfiguration = {
    activeLocations: null,
    activeLocationsList: [],
    availableDates: {},
    propertyDates: {},
  };

  get config(): LocationConfiguration {
    return this.configuration;
  }

  getActiveLocationsList(): Observable<LocationItem[]> {
    return this.http
      .get<LocationItem[]>(`${environment.apiURL}properties/search/locations`)
      .pipe(tap(data => (this.configuration.activeLocationsList = data)));
  }

  getPropertyDates(_id: string): Observable<DatesResponse> {
    return this.http
      .get<DatesResponse>(
        `${environment.apiURL}properties/search/availability/schedule`,
        {
          params: { 'filter[locations][]': `property:${_id}` },
          headers: this.headers,
        }
      )
      .pipe(
        tap(propertyDates => (this.configuration.propertyDates = propertyDates))
      );
  }
}
