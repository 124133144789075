import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar.component';
import { NgbDatepickerModule, NgbPopoverModule } from 'ng-bootstrap14';
import { InputNumberComponent } from '../input-number/input-number.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgPluralizeService } from 'ng-pluralize';

@NgModule({
  declarations: [SearchBarComponent],
  imports: [
    CommonModule,
    InputNumberComponent,
    NgbPopoverModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
  ],
  providers: [NgPluralizeService],
  exports: [SearchBarComponent],
})
export class SearchBarModule {}
