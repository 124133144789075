import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { checkUrl } from 'src/app/Deployment';

export interface HotelRatesRequest {
  start_date: string;
  end_date: string;
  hotel_id: string;
  adults: number;
  children: number;
}

@Injectable({
  providedIn: 'root',
})
export class HotelDescriptionService {
  headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
  }

  getHotelDetails(id: string): Observable<any> {
    return this.http.get(checkUrl() + 'getHotelDetials/' + id, {
      headers: this.headers,
    });
  }

  checkPromoCode(code: string) {
    return this.http.post(
      checkUrl() + 'ApplyPromoCode/',
      { code },
      { headers: this.headers }
    );
  }

  getArkianeAvailability(
    checkInDate: string,
    checkOutDate: string,
    hotelCode: string,
    agencyData: string,
    providerName: string,
    occupants: any[]
  ) {
    const reqBody = {
      checkIn: checkInDate,
      checkOut: checkOutDate,
      hotel_code: hotelCode,
      agency_data: agencyData,
      providerName: providerName,
      occupants: occupants,
    };

    return this.http.post(checkUrl() + 'arkianeGetAvailability/', reqBody, {
      headers: this.headers,
    });
  }

  postAccomodationEnquiry(
    name: string,
    email: string,
    phoneNumber: string,
    message: string,
    contactType: string,
    totalDays: string,
    totalGuests: string,
    hotelName: string,
    hotelId: string,
    checkInDate: string,
    checkOutDate: string,
    guests: string,
    resort: string,
    url: string
  ) {
    const reqBody = {
      name: name,
      email: email,
      phone_no: phoneNumber,
      itinerary: totalDays + ' days ' + totalGuests + ' people',
      accommodation_place: hotelName,
      enquire_message: message,
      contact_type: contactType,
      hotelId: hotelId,
      checkInDate: checkInDate,
      checkOutDate: checkOutDate,
      guests: guests,
      resort: resort,
      url: url,
    };

    // console.log(reqBody);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(checkUrl() + 'saveAccommodationEnquiry/', reqBody, {
      headers: headers,
    });
  }

  getTouristTaxesForArkiane(
    checkInDate: string,
    checkOutDate: string,
    hotelCode: string,
    agencyData: string,
    noOfAdults: number,
    noOfChildren: number
  ) {
    const reqBody = {
      checkIn: checkInDate,
      checkOut: checkOutDate,
      hotel_code: hotelCode,
      agency_data: agencyData,
      adults: noOfAdults,
      child: noOfChildren,
    };

    console.log('Tourist tax req body', reqBody);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(checkUrl() + 'tourist-taxes', reqBody, {
      headers: headers,
    });
  }
  getrelatedblog(data: any) {
    console.log(data);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(
      checkUrl() +
        `/api/get-by-filter?num=${data.cardcount}&resort=${data.resortname}`,
      { headers: headers }
    );
  }
}
