import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { CancellationPolicyModalComponent } from '@shared/components/cancellation-policy-modal/cancellation-policy-modal.component';
import { CopyButtonComponent } from '@shared/components/copy-button/copy-button.component';
import { DamageDepositsModalComponent } from '@shared/components/damage-deposits-modal/damage-deposits-modal.component';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  exports: [
    LoaderComponent,
    CancellationPolicyModalComponent,
    DamageDepositsModalComponent,
    CopyButtonComponent,
    MatDialogModule,
  ],
  declarations: [
    LoaderComponent,
    CancellationPolicyModalComponent,
    DamageDepositsModalComponent,
    CopyButtonComponent,
  ],
  imports: [CommonModule, NgbPopoverModule, MatDialogModule],
})
export class LoginSharedModule {}
