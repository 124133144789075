import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { SharedModule } from '@shared/shared.module';
import { AuthService } from '@v2/core/services/auth.service';

@Component({
  selector: 'app-login-handler',
  templateUrl: './login-handler.component.html',
  styleUrls: ['./login-handler.component.css'],
  standalone: true,
  imports: [SharedModule],
})
export class LoginHandlerComponent implements OnInit {
  isPageLoading: boolean = false;
  constructor(private router: Router, private authService: AuthService) {}
  ngOnInit(): void {
    this.isPageLoading = true;
    const userProfile = this.authService.getLoggedUser();
    if (userProfile.email) {
      this.router.navigate(['/user-profile']);
    } else {
      this.router.navigate([''], { queryParams: { source: 'login' } });
    }
  }
}
