import { Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@v2/core/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalStateService } from '@shared/services/global-state.service';

export interface DialogData {
  isLogin: boolean;
  isRegister: boolean;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  protected destroy$ = new Subject<void>();

  title = 'Login - SkiYodl';

  loginSuccessfulAlert = false;
  isLoading = false;
  errorMsg: string;
  loginForm: FormGroup;
  isForgotActive = false;
  isLoginActive = false;
  isRegisterActive = false;
  isResetActive = false;
  isLogin: boolean;
  hideBackToLoginInRegister = false;

  constructor(
    private authService: AuthService,
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router,
    private location: Location,
    private fb: FormBuilder,
    private globalState: GlobalStateService,
    public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.isLogin = this.data?.isLogin;
    this.isRegisterActive = this.data?.isRegister;
    if (this.isRegisterActive) {
      this.hideBackToLoginInRegister = true;
    }
    this.isLoginActive = this.isRegisterActive ? !this.isRegisterActive : true;
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: this.title,
    });
    this.metaTagService.updateTag({
      property: 'og:title',
      content: this.title,
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: 'https://www.skiyodl.com/',
    });
    this.metaTagService.updateTag({
      name: 'twitter:title',
      content: this.title,
    });

    this.isLoading = false;
    this.loginSuccessfulAlert = false;
    this.errorMsg = '';
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCancel() {
    this.dialogRef.close();
    this.isForgotActive = false;
    this.isLoginActive = false;
    this.isRegisterActive = false;
    this.isResetActive = false;
  }

  onBackToLogin(event: any) {
    this.isLoginActive = true;
    this.isForgotActive = false;
    this.isRegisterActive = false;
  }

  onForgotPassword() {
    this.isForgotActive = true;
    this.isLoginActive = false;
    this.isRegisterActive = false;
  }

  onLogin(): void {
    if (!this.loginForm.valid) {
      return;
    }
    this.isLoading = true;
    this.loginSuccessfulAlert = false;
    this.errorMsg = '';

    const creds = this.loginForm.value;
    this.authService
      .login(creds.email, creds.password)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.loginSuccessfulAlert = true;
          this.isLoading = false;
          setTimeout(() => {
            this.dialogRef.close();
            this.globalState.reloadCurrentRoute();
          }, 1000);
        },
        err => {
          this.errorMsg = err.error.error;
          this.isLoading = false;
        }
      );
  }

  onRegister() {
    this.isForgotActive = false;
    this.isLoginActive = false;
    this.isRegisterActive = true;
  }
}
