import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { Observable } from 'rxjs';
// import { AgmCoreModule } from '@agm/core';
import { AuthGuard } from './Auth.guard';
import { CookieService } from 'ngx-cookie-service';
import * as Sentry from '@sentry/angular';
import { Router, RouterModule } from '@angular/router';
import { AuthInterceptor } from './auth-interceptor';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';
import { AppComponent } from './app.component';
import {
  LocationItem,
  LocationService,
} from '@v2/core/services/location.service';
import { LoaderInterceptor } from '@v2/core/interceptors/loader.interceptor';
import { InspirationService } from '@v2/core/services/inspiration.service';
import { Inspiration } from '@v2/core/models/inspiration.model';
import { of as observableOf } from 'rxjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HammerModule } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { LegacyUrlGuard } from '@v2/core/guards/legacy-url.guard';
import { SlugifyPipe } from '@v2/core/pipes/slugify.pipe';
import { StorageService } from '@v2/core/services/storage.service';
import { DatesResponse } from '@shared/models/calendar-response.model';

function loadActiveLocationsListFactory(
  service: LocationService
): () => Observable<LocationItem[]> {
  return () => service.getActiveLocationsList();
}

function loadPropertyDatesFactory(
  service: LocationService
): () => Observable<DatesResponse> {
  // @todo: refactor. Somewhat hack-ish but route params aren't available atm
  const path = location.pathname.split('/');

  if (path[1] !== 'search' && path.length === 6) {
    return () => service.getPropertyDates(path[4]);
  }

  return () => observableOf({});
}

function cleanPrevStorageVerionFactory(
  service: StorageService
): () => Observable<void> {
  const email = localStorage.getItem('email');
  const token = localStorage.getItem('token');
  if (email?.indexOf('data') === -1) {
    service.setItem('email', email);
  }
  if (token?.indexOf('data') === -1) {
    service.setItem('token', token);
  }
  return () => observableOf();
}

function loadInspirationsFactory(
  service: InspirationService
): () => Observable<Inspiration[]> {
  return () => service.getList();
}

class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    HammerModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyB5hqmIsyCTNp3jYeOhNcm9eOQO1JwQteI'
    // })
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    AuthGuard,
    LegacyUrlGuard,
    SlugifyPipe,
    CookieService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: cleanPrevStorageVerionFactory,
      deps: [StorageService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadActiveLocationsListFactory,
      deps: [LocationService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadInspirationsFactory,
      deps: [InspirationService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadPropertyDatesFactory,
      deps: [LocationService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '62796056116-rslm9vlill0pljd90jge725hcarsjkos.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('629524511092196'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
