import * as moment from 'moment';
import {
  CancellationPolicy,
  CancellationPolicyProvider,
} from '../models/cancellation-policy.model';

/**
 * @todo: Refactor data: any to have proper interface definition
 */
export class CancellationPolicyUtil {
  private static emptyPolicy: CancellationPolicy = {
    freeCancellationDate: null,
    cancelBetweenArray: [],
    extraTexts: [],
    link: null,
  };

  static getPolicy(
    provider: CancellationPolicyProvider,
    data: any,
    checkIn?: string
  ): CancellationPolicy {
    switch (provider) {
      case 'Arkiane':
        return CancellationPolicyUtil.arkianePolicy(data, checkIn);
      case 'TravelGateX':
      default:
        return CancellationPolicyUtil.tgxPolicy(data);
    }
  }

  private static arkianePolicy(data: any, checkIn: string): CancellationPolicy {
    let cancellationPolicy: CancellationPolicy = { ...this.emptyPolicy };
    for (let policy of data.local_policies) {
      if (
        policy.policy_name.includes(':') &&
        !policy.policy_name.includes('http')
      ) {
        if (policy.policy_name.split(':')[1].toUpperCase() === ' NO CHARGE') {
          const days = parseInt(
            policy.policy_name.replace(/[^0-9\.]/g, ''),
            10
          );
          const reformattedCheckinDate =
            checkIn.split('/')[1] +
            '/' +
            checkIn.split('/')[0] +
            '/' +
            checkIn.split('/')[2];
          const arrivalDate = new Date(reformattedCheckinDate);
          cancellationPolicy.freeCancellationDate = moment(arrivalDate)
            .subtract(days, 'days')
            .format('DD/MM/YYYY');
        }

        policy.policy_name = policy.policy_name.split('• ')[1];

        cancellationPolicy.cancelBetweenArray.push({
          key: policy.policy_name.split(':')[0],
          value: policy.policy_name.split(':')[1],
        });
      } else if (policy.policy_name.includes('http')) {
        cancellationPolicy.link = 'http' + policy.policy_name.split('http')[1];
      } else {
        policy.policy_name = policy.policy_name.split('• ')[1];
        cancellationPolicy.extraTexts.push(policy.policy_name);
      }
    }

    return cancellationPolicy;
  }

  private static tgxPolicy(data: any): CancellationPolicy {
    let cancellationPolicy: CancellationPolicy = { ...this.emptyPolicy };
    if (data.cancelPolicy.refundable === true) {
      data.cancelPolicy.cancelPenalties =
        data.cancelPolicy.cancelPenalties.reverse();
      for (let policy of data.cancelPolicy.cancelPenalties) {
        const policyVal =
          policy.penaltyType === 'PERCENT'
            ? policy.value.split('.')[0] + '%'
            : `£${policy.value}`;

        cancellationPolicy.cancelBetweenArray.push({
          key:
            'Cancellation after ' +
            moment(policy.deadline).format('D MMM YYYY, h:mm a'),
          value: `${policyVal} of payment withheld`,
        });
      }
    }
    return cancellationPolicy;
  }
}
