import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class BaseHttpService {
  headers: HttpHeaders;

  // @todo: Remove this monstrosity, and some methods don't need auth at all
  constructor(
    protected http: HttpClient,
    protected storageService: StorageService
  ) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
  }
}
