import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '@v2/core/services/auth.service';
import { StorageService } from '@v2/core/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = this.storageService.getItem('token');
    if (token !== null && !req.url.includes('auth/')) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          !authReq.url.includes('auth/') &&
          error.status === 401
        ) {
          return this.handle401Error(authReq, next);
        }

        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.storageService.getItem('refresh');

      if (token) {
        return this.authService.refresh(token).pipe(
          switchMap(response => {
            this.isRefreshing = false;
            location.reload();
            return next.handle(
              this.addTokenHeader(request, response.tokens.access)
            );
          }),
          catchError(err => {
            this.isRefreshing = false;
            this.storageService.removeItem('refresh');
            sessionStorage.clear();
            localStorage.clear();
            this.storageService.setItem('redirectTo', location.href);
            // location.href = location.origin + '/';
            location.reload();
            return throwError(err);
          })
        );
      } else {
        sessionStorage.clear();
        localStorage.clear();
        this.storageService.setItem('redirectTo', location.href);
        // location.href = location.origin + '/';
        location.reload();
      }
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }
}
