import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NewPasswordService } from './new-password.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '@v2/core/services/storage.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css'],
})
export class NewPasswordComponent implements OnInit {
  email: string;
  newPassword: string;
  confirmPassword: string;
  confPasswordNotMatchErr: boolean = false;
  passwordResetSuccessfulAlert: boolean = false;
  passwordLengthError: boolean = false;
  isLoading: boolean = false;
  @Output() backToLoginClicked = new EventEmitter();

  newPassForm: FormGroup;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private newPasswordService: NewPasswordService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.newPassForm = this.fb.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
    this.email = this.storageService.getItem('email');
  }

  onBackToLogin() {
    this.backToLoginClicked.emit();
  }

  onCancel() {
    this.router.navigate(['/home']);
  }

  onResetPassword(): void {
    if (!this.newPassForm.valid) return;

    this.isLoading = true;
    this.confPasswordNotMatchErr = false;
    this.passwordResetSuccessfulAlert = false;
    this.passwordLengthError = false;

    if (
      this.newPassForm.value.password !== this.newPassForm.value.confirmPassword
    ) {
      this.confPasswordNotMatchErr = true;
      this.isLoading = false;
      return;
    }

    this.newPasswordService
      .setNewPassword(this.email, this.newPassForm.value.password)
      .subscribe(
        data => {
          if (data['result']) {
            this.passwordResetSuccessfulAlert = true;
            this.isLoading = false;
            localStorage.removeItem('email');
            setTimeout(() => {
              this.dialog.closeAll();
            }, 1000);
          }
        },
        err => {
          this.passwordLengthError = true;
          this.isLoading = false;
        }
      );
  }
}
