<mat-dialog-content class="mat-typography">
  <div class="main-card container bg-white">
    <div class="row crossBtn" (click)="onCancel()">
      <h1 style="padding: 10px 10px 0 0; margin: 0">
        <img
          src="/assets/Group 503.png"
          style="
            float: right;
            cursor: pointer;
            margin-bottom: 25px;
            margin-right: -40px;
          " />
      </h1>
    </div>
    <ng-container *ngIf="isLoginActive">
      <div class="">
        <h1 class="login-title">Login</h1>
        <form [formGroup]="loginForm" *ngIf="loginForm">
          <input
            class="TextField"
            type="email"
            placeholder="Email"
            formControlName="email" />
          <input
            class="TextField"
            type="password"
            placeholder="Password"
            formControlName="password" />
        </form>

        <p class="create-account-msg">
          Haven’t created an account previously? Click “Forgot your password?“
          below
        </p>

        <!-- Alerts -->
        <div class="text-center">
          <p
            style="font: normal normal 15px Soleil; color: #d35551"
            *ngIf="errorMsg !== ''">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{ errorMsg }}
          </p>
          <p
            style="font: normal normal 15px Soleil; color: #4a4a4a"
            *ngIf="loginSuccessfulAlert">
            <i class="fa fa-check" aria-hidden="true"></i> Login Successful!
          </p>
        </div>

        <button
          *ngIf="isLoading === false"
          class="btn btn-danger login-btn"
          [disabled]="!loginForm.valid"
          (click)="onLogin()">
          <span>Login</span>
          <i class="fa fa-arrow-right"></i>
        </button>
        <div class="row" style="margin: 8px 0 0" *ngIf="isLoading === true">
          <div
            class="text-center"
            style="
              background-color: #d35551;
              color: white;
              padding: 10px;
              border-radius: 5px;
              cursor: pointer;
            ">
            <div class="loader"></div>
          </div>
        </div>
        <div class="row dontHaveAccount" style="margin: 40px 0 60px 0">
          <div class="col-6" (click)="onForgotPassword()">
            <p
              style="
                font: normal normal 600 11px Soleil;
                color: gray;
                margin: 0;
                cursor: pointer;
              ">
              Forgot your password?
            </p>
          </div>
          <div *ngIf="!isLogin" class="col-6" (click)="onRegister()">
            <p
              style="
                font: normal normal 600 11px Soleil;
                color: gray;
                margin: 0;
                cursor: pointer;
                float: right;
              ">
              Don’t have an account?
            </p>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isForgotActive">
      <app-forgot-password
        (backToLoginClicked)="onBackToLogin($event)"></app-forgot-password>
    </ng-container>
    <ng-container *ngIf="isRegisterActive">
      <app-register
        [hideBackToLoginInRegister]="hideBackToLoginInRegister"
        (backToLoginClicked)="onBackToLogin($event)"></app-register>
    </ng-container>
  </div>
</mat-dialog-content>
