import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { checkUrl } from 'src/app/Deployment';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordService {
  constructor(private http: HttpClient) {}

  sendOTPonEmail(email: string) {
    const reqBody = {
      email: email,
    };

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(checkUrl() + 'generateOtp/', reqBody, {
      headers: headers,
    });
  }
}
