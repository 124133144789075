import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DatesResponse } from '@shared/models/calendar-response.model';
import { CalendarService } from '@shared/services/calendar.service';
import { LocationService } from '../services/location.service';

@Injectable({ providedIn: 'root' })
export class AvailableDatesResolver implements Resolve<DatesResponse> {
  constructor(
    private service: CalendarService,
    private locationService: LocationService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<DatesResponse> | Promise<DatesResponse> | DatesResponse {
    if (Object.keys(this.locationService.config.availableDates).length) {
      return this.locationService.config.availableDates;
    }
    const queryParams = route.queryParams;
    let result: DatesResponse = {};
    if (queryParams?.areaType && queryParams?.locationId) {
      return this.service.getDates([
        `${queryParams.areaType}:${queryParams.locationId}`,
      ]);
    }

    const params = route.firstChild?.firstChild?.params;
    if (params?.areaType && params?.locationId) {
      return this.service.getDates([`${params.areaType}:${params.locationId}`]);
    }

    return this.service.getDates([]);
  }
}
