import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseHttpService } from './base-http-service';
import { Inspiration, InspirationsResponse } from '../models/inspiration.model';

@Injectable({ providedIn: 'root' })
export class InspirationService extends BaseHttpService {
  private list: Inspiration[];

  get inspirations(): Inspiration[] {
    return this.list;
  }

  getList(): Observable<Inspiration[]> {
    return this.http
      .get<InspirationsResponse>(`${environment.apiURL}getInspirationsLIst`, {
        headers: this.headers,
      })
      .pipe(
        map(response => response.inspirations),
        tap(list => (this.list = list))
      );
  }
}
