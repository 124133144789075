import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { checkUrl } from 'src/app/Deployment';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  constructor(private http: HttpClient) {}

  verifyOTP(email: string, otp: string) {
    const reqBody = {
      email: email,
      OTP: otp,
    };

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(checkUrl() + 'verifyOtp/', reqBody, {
      headers: headers,
    });
  }
}
