import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CalendarRequest } from '@shared/models/calendar-request.model';
import { environment } from 'src/environments/environment';
import {
  CalendarResponse,
  DatesResponse,
} from '@shared/models/calendar-response.model';

@Injectable({ providedIn: 'root' })
export class CalendarService {
  constructor(private http: HttpClient) {}

  getAvailableDates(options: CalendarRequest): Observable<CalendarResponse> {
    const params = new HttpParams({ fromObject: options as any });
    return this.http.get<CalendarResponse>(
      `${environment.apiURL}getAvailableDates?${params.toString()}`
    );
  }

  getDates(locations: string[]): Observable<DatesResponse> {
    let params = '';
    const paramsArray = locations
      .filter(l => l.indexOf('undefined') === -1)
      .map(l => (params += `filter[locations][]=${l}`));
    return this.http.get<DatesResponse>(
      `${
        environment.apiURL
      }properties/search/availability/schedule?${paramsArray.join('&')}`
    );
  }
}
