import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookingResolver } from './booking/resolvers/booking.resolver';
import { LegacyUrlGuard } from '@v2/core/guards/legacy-url.guard';
import { AvailableDatesResolver } from '@v2/core/resolvers/available-dates.resolver';
import { LoginHandlerComponent } from './auth/components/login-handler/login-handler.component';
import { AuthGuard } from './Auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginHandlerComponent,
  },
  // Search Module
  {
    path: '',
    resolve: {
      availableDates: AvailableDatesResolver,
    },
    loadChildren: () =>
      import('./search/search.module').then(m => m.SearchModule),
  },
  // "Static" Pages Module
  {
    path: '',
    resolve: {
      availableDates: AvailableDatesResolver,
    },

    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  // Auth Module
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  // Profile Module
  {
    path: '',
    resolve: {
      availableDates: AvailableDatesResolver,
    },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./profile/profile.module').then(m => m.ProfileModule),
  },
  // Booking Module
  {
    path: 'bookings',
    resolve: {
      data: BookingResolver,
      availableDates: AvailableDatesResolver,
    },
    loadChildren: () =>
      import('./booking/booking.module').then(m => m.BookingModule),
  },
  // Old App Monolith
  {
    path: '',
    resolve: {
      availableDates: AvailableDatesResolver,
    },
    loadChildren: () =>
      import('./monolith/monolith.module').then(m => m.MonolithModule),
  },
  // V2
  {
    path: 'v2',
    resolve: {
      availableDates: AvailableDatesResolver,
    },
    loadChildren: () => import('./v2/v2.module').then(m => m.V2Module),
  },
  {
    path: 'description',
    pathMatch: 'full',
    canActivate: [LegacyUrlGuard],
    resolve: {
      availableDates: AvailableDatesResolver,
    },
    loadChildren: () => import('./v2/v2.module').then(m => m.V2Module),
  },
  // Fallback
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
