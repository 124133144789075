<div class="">
  <div class="register-form">
    <h1 class="sign-up-title">Sign-up</h1>
    <form class="mb-4" [formGroup]="registerForm">
      <input type="email" placeholder="Email" formControlName="email" />
      <input
        type="password"
        placeholder="Password"
        formControlName="password" />
      <input
        type="password"
        placeholder="Confirm Password"
        formControlName="confirmPassword" />
      <div class="policy-checkbox">
        <mat-checkbox formControlName="policy"></mat-checkbox>
        <p style="font: normal normal 600 12px Soleil; color: gray; margin: 0">
          I agree to allow SKIYODL LTD to store and process my personal data and
          agree with the Terms & Conditions and Privacy Policy
        </p>
      </div>
    </form>
    <!-- Alerts -->
    <div
      class="alert-msg"
      style="font: normal normal 15px Soleil; color: #d35551"
      *ngIf="passwordNotMatchError">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      <span>Confirm password doesn't match!</span>
    </div>
    <div
      class="alert-msg"
      style="font: normal normal 15px Soleil; color: #4a4a4a"
      *ngIf="regsitrationSuccessfulAlert">
      <i class="fa fa-check" aria-hidden="true"></i>
      <span>Registration successful!</span>
    </div>
    <div
      class="alert-msg"
      style="font: normal normal 15px Soleil; color: #d35551"
      *ngIf="errorMsg && errorMsg !== 'Duplicate field'">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      <span>{{ errorMsg }}</span>
    </div>

    <button
      *ngIf="isLoading === false"
      class="btn btn-danger login-btn"
      [disabled]="!registerForm.valid"
      (click)="onRegister()">
      <span>Register</span>
      <i class="fa fa-arrow-right"></i>
    </button>
  </div>
  <div
    class="row"
    style="margin: 8px 0 8px 0; padding-right: 20px; padding-left: 20px"
    *ngIf="isLoading === true">
    <div
      class="text-center"
      style="
        background-color: #d35551;
        color: white;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
      ">
      <div class="loader"></div>
    </div>
  </div>
  <div
    *ngIf="!hideBackToLoginInRegister"
    class="text-center"
    style="margin: 1rem auto"
    (click)="onBackToLogin()">
    <p
      style="
        font: normal normal 600 12px Soleil;
        color: gray;
        margin: 0;
        cursor: pointer;
      ">
      Back to login
    </p>
  </div>
</div>
