import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewPasswordComponent } from './new-password.component';
import { LoginSharedModule } from '../login-shared/login-shared.module';

@NgModule({
  declarations: [NewPasswordComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LoginSharedModule],
  exports: [NewPasswordComponent],
})
export class NewPasswordModule {}
