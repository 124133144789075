import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancellationPolicyModalComponent } from './components/cancellation-policy-modal/cancellation-policy-modal.component';
import { DamageDepositsModalComponent } from './components/damage-deposits-modal/damage-deposits-modal.component';
import { HeaderModule } from './components/header/header.module';
import { LoaderComponent } from './components/loader/loader.component';
import { FooterModule } from './components/footer/footer.module';
import { CopyButtonComponent } from './components/copy-button/copy-button.component';
import { NgbPopoverModule } from 'ng-bootstrap14';
import { LoginSharedModule } from '../auth/components/login-shared/login-shared.module';

@NgModule({
  exports: [LoginSharedModule, HeaderModule, FooterModule],
  declarations: [],
  imports: [CommonModule, HeaderModule, FooterModule, LoginSharedModule],
})
export class SharedModule {}
