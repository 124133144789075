import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResortListResponse } from '@shared/models/resort-list-reponse.model';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private userProfileTab = new BehaviorSubject('my-trips');
  currentUserProfileTab = this.userProfileTab.asObservable();

  constructor(private http: HttpClient) {}

  getResortsList(): Observable<ResortListResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get<ResortListResponse>(environment.apiURL + 'resorts', {
      headers: headers,
    });
  }

  changeActiveUserProfileTab(tagName: string) {
    this.userProfileTab.next(tagName);
  }
}
