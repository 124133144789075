<div class="form-group">
  <div [innerText]="placeholder" class="placeholder"></div>
  <div class="entry">
    <button
      type="button"
      class="modify decrease"
      (click)="decrease($event)"
      [disabled]="value <= min">
      <i class="fa fa-minus-circle"></i>
    </button>
    <input type="text" readonly [value]="value" size="1" />
    <button
      type="button"
      class="modify increase"
      (click)="increase($event)"
      [disabled]="value >= max">
      <i class="fa fa-plus-circle"></i>
    </button>
  </div>
</div>
