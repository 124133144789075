import { Injectable } from '@angular/core';

interface ExpirableStoredData {
  expiresAt: number;
  data: any;
}

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor() {}

  setItem(key: string, data: any, expiresAt?: number): void {
    localStorage.setItem(
      key,
      JSON.stringify({
        data,
        expiresAt: expiresAt || Date.now() + 60 * 60 * 24 * 365 * 1000,
      })
    );
  }

  getItem(key: string): any {
    const rawData: ExpirableStoredData = JSON.parse(localStorage.getItem(key));
    if (rawData?.expiresAt <= Date.now() || !rawData) {
      localStorage.removeItem(key);
      return null;
    }

    return rawData.data;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
