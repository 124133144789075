import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from '@v2/core/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { GlobalStateService } from '@shared/services/global-state.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  title = 'Register - SkiYodl';
  private destroy$ = new Subject<void>();
  @Input() hideBackToLoginInRegister: any;

  passwordNotMatchError = false;
  regsitrationSuccessfulAlert = false;
  errorMsg: string;
  isLoading: boolean;

  registerForm: FormGroup;
  @Output() backToLoginClicked = new EventEmitter();

  constructor(
    private authService: AuthService,
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private globalState: GlobalStateService
  ) {}

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.min(5)]],
      confirmPassword: ['', [Validators.required, Validators.min(5)]],
      policy: ['', Validators.required],
    });

    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: this.title,
    });
    this.metaTagService.updateTag({
      property: 'og:title',
      content: this.title,
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: 'https://www.skiyodl.com/register',
    });
    this.metaTagService.updateTag({
      name: 'twitter:title',
      content: this.title,
    });

    this.passwordNotMatchError = false;
    this.regsitrationSuccessfulAlert = false;
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onRegister() {
    this.isLoading = true;
    this.passwordNotMatchError = false;
    this.regsitrationSuccessfulAlert = false;
    this.errorMsg = undefined;
    if (
      this.registerForm.value.password !==
      this.registerForm.value.confirmPassword
    ) {
      this.passwordNotMatchError = true;
      this.isLoading = false;
      return;
    }

    if (!this.registerForm.valid) return;
    const creds = {
      email: this.registerForm.value.email,
      password: this.registerForm.value.password,
    };
    this.authService
      .register(creds.email, creds.password)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.regsitrationSuccessfulAlert = true;
          setTimeout(() => {
            this.dialog.closeAll();
            this.globalState.reloadCurrentRoute();
          }, 1000);
        },
        err => {
          if (err.error.statusCode === 409) {
            this.errorMsg = err.error.message;
          } else if (err.error.statusCode === 400) {
            this.errorMsg = err.error.message[0];
          }
          this.isLoading = false;
        },
        () => (this.isLoading = false)
      );
  }

  onBackToLogin() {
    this.backToLoginClicked.emit();
  }

  onCancel() {
    this.router.navigate([sessionStorage.getItem('prevRoute')]);
  }
}
