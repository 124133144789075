import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ForgotPasswordService } from './forgot-password.service';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { StorageService } from '@v2/core/services/storage.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent {
  emailCtrl = new FormControl('', [Validators.required, Validators.email]);
  errorMsg: string;
  isLoading: boolean = false;
  OTPSentAlert: boolean = false;
  isForgotPasswordActive = true;
  isOtpScreenActive = false;
  @Output() backToLoginClicked = new EventEmitter();
  @Output() otpRequested = new EventEmitter();

  constructor(
    private storageService: StorageService,
    private router: Router,
    private forgotPasswordService: ForgotPasswordService
  ) {}

  onBackToLogin() {
    this.backToLoginClicked.emit();
  }

  onCancel() {
    this.router.navigate(['/home']);
  }

  onReset() {
    if (!this.emailCtrl.valid) return;

    this.isLoading = true;
    this.OTPSentAlert = false;
    this.errorMsg = undefined;

    this.forgotPasswordService.sendOTPonEmail(this.emailCtrl.value).subscribe(
      data => {
        if (data['result']) {
          this.OTPSentAlert = true;
          this.storageService.setItem('email', this.emailCtrl.value);
          this.isLoading = false;
          setTimeout(() => {
            // this.router.navigate(['/otp']);
            this.isOtpScreenActive = true;
            this.isForgotPasswordActive = false;
          }, 1000);
        }
      },
      err => {
        if (err.status === 422) {
          this.errorMsg = err.error.error.msg;
          this.isLoading = false;
        } else if (err.status === 404) {
          this.errorMsg = err.error.error;
          this.isLoading = false;
        } else {
          this.errorMsg = 'Some issue occured. Try again';
          this.isLoading = false;
        }
      }
    );
  }
}
