import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ResetPasswordService } from './reset-password.service';
import { FormControl, Validators } from '@angular/forms';
import { StorageService } from '@v2/core/services/storage.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  otp = new FormControl('', [Validators.required]);
  email: string;
  OTPVerifiedAlert: boolean = false;
  wrongOTPError: boolean = false;
  isLoading: boolean = false;
  @Output() backToLoginClicked = new EventEmitter();
  isOtpActive = false;
  isResetPasswordActive = false;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private resetPasswordService: ResetPasswordService
  ) {}

  ngOnInit(): void {
    this.isOtpActive = true;
    this.email = this.storageService.getItem('email');
  }

  onBackToLogin() {
    this.backToLoginClicked.emit();
  }

  onCancel() {
    this.router.navigate(['/home']);
  }

  onProceed() {
    if (!this.otp.valid) return;

    this.isLoading = true;
    this.OTPVerifiedAlert = false;
    this.wrongOTPError = false;

    this.resetPasswordService.verifyOTP(this.email, this.otp.value).subscribe(
      data => {
        if (data['result']) {
          this.OTPVerifiedAlert = true;
          this.isLoading = false;
          setTimeout(() => {
            this.isResetPasswordActive = true;
            this.isOtpActive = false;
          }, 1000);
        }
      },
      err => {
        this.wrongOTPError = true;
        this.isLoading = false;
      }
    );
  }
}
